[data-alert-code-status]{
  .alert {
    text-align: center;
    border-radius: .5rem;

    &.warning {
      background-color: var(--brand-red);
      color: #ffffff;
    }

    &.info {
      background-color: var(--brand-yellow);
    }
  }

}
