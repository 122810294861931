$axes-list: 'reconhecimento', 'relacionamento', 'relevancia', 'reputacao', 'resiliencia';

@mixin axes-color($axis, $type: 'bg', $opacity: 1) {
  @if $axis == 'reconhecimento' {
    @if $type == 'bg' {
      background-color: rgba(var(--brand-red-rgb), $opacity);
    }
    @else {
      #{$type}: var(--brand-red);
    }
  }

  @if $axis == 'relacionamento' {
    @if $type == 'bg' {
      background-color: rgba(var(--brand-yellow-rgb), $opacity);
    }
    @else {
      #{$type}: var(--brand-yellow);
    }
  }

  @if $axis == 'relevancia' {
    @if $type == 'bg' {
      background-color: rgba(var(--brand-blue-rgb), $opacity);
    }
    @else {
      #{$type}: var(--brand-blue);
    }
  }

  @if $axis == 'reputacao' {
    @if $type == 'bg' {
      background-color: rgba(var(--brand-green-rgb), $opacity);
    }
    @else {
      #{$type}: var(--brand-green);
    }
  }

  @if $axis == 'resiliencia' {
    @if $type == 'bg' {
      background-color: rgba(var(--brand-grey-rgb), $opacity);
    }
    @else {
      #{$type}: var(--brand-grey);
    }
  }
}
