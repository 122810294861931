/**
  Listas
 */
.rindex-item {
  // IonItem
  //padding-bottom: 10px;
  .label {
    color: var(--brand-grey);
    font-weight: 700;
  }

  .note {
    font-size: 0.75em;
    opacity: 0.7;
  }
}

.item-detail {
  border-left: solid 3px #eee;
  padding-left: 1rem;
  overflow: hidden;
  @each $axis in $axes-list {
    &.#{$axis} {
      @include axes-color($axis, "border-color");

      ion-label {
        font-weight: 400;
        @include axes-color($axis, "color");
      }
    }
  }
}

.rindex-axis {
  @each $axis in $axes-list {
    &.#{$axis} {
      text-transform: uppercase;
      font-weight: 700;
      @include axes-color($axis, "color");
      ion-item-divider {
      }
    }
  }
}

/**
  Lista de perguntas
 */
.rindex-questions-list {
  ion-item-divider {
    margin-top: 15px;
    --background: transparent;
    --padding-start: 0;
    ion-label {
      font-weight: 700;
    }
  }
  @each $axis in $axes-list {
    &.#{$axis} {
      ion-item-divider {
        @include axes-color($axis, "color");
      }
      ion-icon.answered {
        @include axes-color($axis, "color");
      }
    }
  }
  .item-inner {
  }
  ion-item {
    --border-color: #eee;
    font-size: 0.9em;
    ion-label {
      font-weight: 700;
    }
    ion-icon {
      margin-right: -10px;
    }
  }
}

/* input em form pegam cor de fundo pdrão */
form ion-item {
  --background: var(--brand-bg);
}

.rindex-color-bar {
  position: relative;
  background-color: rgba(97, 110, 126, 0.05);
  height: 6px;
  margin: 6px 0;

  .axis {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20%;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }

  @each $axis in $axes-list {
    $left: index($axes-list, $axis);
    .#{$axis} {
      left: #{($left - 1) * 20 + "%"};
      @include axes-color($axis, "bg", 0.2);

      div {
        @include axes-color($axis);
      }
    }
  }
}

.rindex-axis-bar {
  margin-bottom: 1.5em;

  .name {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .bar {
    border-radius: 0 20px 20px 0;
  }

  .width {
    text-align: right;
    height: 20px;
    border-radius: 0 20px 20px 0;
    overflow: hidden;

    span {
      font-size: 0.8em;
      line-height: 1em;
      color: #fff;
      padding: 5px;
    }
  }

  @each $axis in $axes-list {
    .#{$axis} {
      @include axes-color($axis, "bg", 0.2);

      .width {
        @include axes-color($axis);
      }
    }
  }

  ion-card-content &:last-child {
    margin-bottom: 0;
  }
}

@media all and (max-width: 320px) {
  .rindex-axis-bar {
    margin-bottom: 1em;
  }
}
