[data-comp-splashscreen] {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .wrapper {
    position: relative;

    .msg {
      text-transform: uppercase;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      text-align: center;
      margin-top: -10px;
    }
  }
}

