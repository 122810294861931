[data-comp-statusheader] {
  ion-card {
    margin-bottom: 12px;
  }
  ion-card-content {
    padding: 0 5px;
  }

  ion-col:not(:first-child) {
    text-align: right;
  }

  .entity {
    padding: 10px 0;
  }

  .info {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    //height: 30px;
    ion-icon {
    }
  }
}
