[data-comp-login] {
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 32px);
    align-content: center;
    justify-content: center;
  }

  .login--logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 260px;
    margin: 40px auto 20px auto;
    flex-grow: 1;

    img {
      width: 100%;
      height: auto;
    }
  }

  .login--footer {
    display: block;
    padding: 15px;
    font-size: 0.8em;
    text-align: center;
    text-decoration: none;
    color: var(--ion-color-medium-shade);
  }

  .login--note {
    text-align: center;
    font-size: .7em;
    color: #c3c3c3;
  }

}

/* ALERTAS */
.rindex-login-alert {
  .alert-message {

    padding: 10px 20px 0;
    img {
      width: 70%;
      height: auto;
      margin: 0 auto;
      display: block;
    }
    .subtit {
      margin: 20px 0 0;
      font-weight: 700;
      font-size: 1em;
    }
  }
  .alert-input {
    text-align: center;
    height: 40px;
  }
}
