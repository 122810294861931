[data-comp-spinner]{

  width: 100%;
  text-align: center;
  padding: 5%;

  ion-spinner {
    transform: scale(1.2);
  }
}
