[data-page-tutorial]{
  height: 90%;
  ion-slides {
    height: 100%;
  }
  ion-card {
    // margin-left: 0;
    // margin-right: 0;
  }

  .final-content {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
  }
}
