
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating-fast {
  animation: rotating 1s linear infinite;
}
.rotating {
  animation: rotating 2s linear infinite;
}
.rotating-slow {
  animation: rotating 3s linear infinite;
}

.rotating-xslow {
  animation: rotating 4s linear infinite;
}
