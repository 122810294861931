@import "mixins";
@import "lists-items";
@import "form-inputs";
@import "buttons-fabs";
@import "alerts-popovers";
@import "animations";

html.ios,
html.md {
  --ion-default-font: "Nunito", "Helvetica Neue", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

h3 {
  font-size: 20px;
}

ion-title {
  --color: rgba(0, 0, 0, .5);
  font-weight: 900;
  text-transform: uppercase;

}

ion-toolbar {
  --background: var(--brand-bg) !important;
  --border-width: 0 !important;

  &:first-child {
    --background: var(--brand-bg) url(/assets/header-color-bar.png) no-repeat 100% 0 / 100% 3px !important;
  }
}

ion-content {
  --background: var(--brand-bg);
}


/* primeiro card da página */
.card__first {
  margin-top: 10px;
}

.text-color {
  @each $axis in $axes-list {
    &.#{$axis} {
        @include axes-color($axis, "color");
    }
  }
}
.ring {
  overflow: visible !important;
  position: relative;
  padding: 0;
  margin: 0;
  height: 30px;
  //box-shadow: 0 0 1px #000;

  .finished {
    position: absolute;
    color: #fff;
    background-color: var(--brand-green);
    border-radius: 100px;
    left: 50%;
    transform: translate(-50%, 0) scale(1.2);
    box-shadow: 0 0 10px rgba(55, 158, 50, 0.6);
    z-index: 99;
  }
  img {
    display: block;
    margin: 0 auto;
    //width: 90%;
    //height: auto;
  }
}
.leave-note {
  width: 100%;
  ion-icon {
    font-size: 35px;
  }
  &.has-note {
    ion-icon{
      font-size: 26px;
    }
  }
}
.text-note-tip {
  color: var(--brand-grey);
  overflow: hidden;
  display: block;
  margin-left: 10px;
  text-overflow: ellipsis;
  width: 100%;
  opacity: .6;
  text-align: left;
}

@media  all and (min-width: 996px){
  .content {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}
