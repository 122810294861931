[data-comp-help]{
  ion-item,
  ion-list-header {
    --background: var(--brand-bg);
    --padding-start: 0;
    p{
      padding-left: 5px;
    }
  }

  ion-item-divider {
    font-weight: 700;
  }
}
