[data-comp-assessments]{
  height: 100%;
}
[data-comp-tutorial] {
  height: 100%;

  ion-slides {
    height: 100%;

    ion-slide {
      padding: 0 2rem;
      height: 100%;
      //background-color: #f3f3f3;
      flex-direction: column;
      .img {
        img {
          display: block;
          margin: 0 auto;
        }
      }
      h1 {
        margin: 1rem 0 .5rem;
      }
      .pre-title {
        text-transform: uppercase;
        font-weight: 700;
        color: #a8a8a8;
        display: block;
        margin-bottom: 1rem;
      }
      p {
        width: 100%;
        font-size: .9em;
        margin: .5rem 0;
      }
    }
  }
}
