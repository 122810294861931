@import "../../theme/mixins";
[data-comp-questions] {

  ion-slides {

    ion-slide {
      > div {
        width: 100%;
        min-height: 50vh;
        text-align: left;
        padding: 10px 15px 0;
        margin-bottom: 10px;
      }
      .question {
        font-weight: 700;
        font-size: 1.0em;
      }
      .leave-note {
        margin: 10px 0 10px -7px;
      }
      ion-item {
        --min-height: 40px;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        //--inner-padding-top: 0;
        //--inner-padding-bottom: 0;
        font-size: .8em;
        ion-label {
        }
      }
      ion-checkbox {
        --size: 20px;
      }

      @each $axis in $axes-list {
        .#{$axis} ion-checkbox{
          @include axes-color($axis, '--background-checked');
          @include axes-color($axis, '--border-color-checked');
        }
        .#{$axis} ion-button{
          @include axes-color($axis, '--color');
        }
      }

    }
  }
}
