.login--card {
  width: 100%;
  margin: 20px 0;
  .card-content-ios,
  .card-content-md {
    padding-top: 10px;
  }

  .login--title {
    font-size: 1em;
    text-align: center;
    margin: 20px 0 0 0;
    color: var(--ion-color-medium);
    text-transform: uppercase;
  }

  .social--links {
    font-size: 2.8em ;
    //display: flex;
    justify-content: center;

    > .social--icon {
      margin: 8px;
    }
  }
}


