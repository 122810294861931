[data-comp-profile]{
  ion-chip {
    height: 50px;
    border-radius: 100px;
    ion-avatar {
    width: 42px;
    height: 42px;
    }
    ion-label {
      padding-right: 1rem;
    }
  }
}
