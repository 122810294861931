[data-comp-about]{

  .logo {
    img {
      width: 50%;
      height: auto;
      margin: 0 auto;
      display: block;
    }
  }
}
