
ion-menu {
  ion-content {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
  }
  ion-footer {
    ion-item {
      font-size: .7em;
      opacity: .5;
    }
  }
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}


ion-menu.md ion-list-header, ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}


ion-menu {
  .menu-profile {
    border-bottom: solid 1px #eeeeee;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  ion-content {
    --padding-bottom: 20px;
  }

  ion-list {
    padding: 20px 0 0 0;
  }

  ion-item {
    --padding-start: 16px;
    --padding-end: 16px;
    --min-height: 50px;

    ion-icon {
      font-size: 24px;
      color: #73849a;
    }
    .selected ion-icon {
      color: var(--ion-color-primary);
    }
  }

  ion-note {
    line-height: 24px;
    font-size: .8em;
  }
}

ion-menu ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu ion-list-header,
ion-menu ion-note {
  padding-left: 16px;
  padding-right: 16px;
}



ion-note {
  display: inline-block;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
