.rindex-status-popopver {

  --min-width: 250px;


  .popover-viewport {
    padding: 10px;

  }

  ion-textarea {
    --padding-start: 10px;
    border: solid 1px #cccccc;
    border-radius: 4px;
    font-size: .8em;
  }

  .axis {
    display: flex;
    justify-content: space-between;
    padding: 7px 20px;
    text-transform: uppercase;
    font-size: .9em;
    font-weight: 700;

    @each $axis in $axes-list {
      &.#{$axis} {
        @include axes-color($axis, 'color');
      }
    }
  }

  .trans {
    opacity: .5;
  }

  @each $axis in $axes-list {
    &.#{$axis} {
      ion-button {
        @include axes-color($axis, '--background');
      }
    }
  }
}


.rindex-entity-info {
  --min-width: 250px;

  .popover-viewport {
    padding: 10px 20px;
  }

  strong {
    font-size: 1.1em;
  }

  .note {
    font-size: .8em;
    color: var(--brand-grey);
  }
}
