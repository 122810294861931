@import "../../theme/mixins";

[data-comp-axisnav] {
  .name {
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 17px;
  }

  ion-grid {
  padding: 0;
  }

  ion-icon {
    font-size: 24px;
  }

  ion-row {
    flex-wrap: nowrap;
  }

  .btn-col {
    padding-top: 0;
    padding-bottom: 0;
    ion-button {
      --padding-start: 0px;
      --padding-end: 0px;
    }

    flex-grow: 1;
  }

  @each $axis in $axes-list {
    &.#{$axis} {
      .name,
      ion-button {
        @include axes-color($axis, 'color');
      }
    }
  }


}
