[data-comp-score] {
  &.content {
    display: flex;
    flex-direction: column;
    height: 80vh;
    justify-content: space-between;
  }
  ion-card {
    ion-card-content {

    }
    min-height: 60px;
    //flex-grow: 1;
  }
  .middle {

  }
  .score-splash {
    position: relative;
    text-align: center;
    max-width: 320px;
    margin: 0 auto;

    ion-fab {
    }
    ion-fab-button {
      --box-shadow: none;

      ion-icon {
        width: 100%;
        height: 100%;
        //color: var(--brand-grey);
        color: #959595;
      }
    }

    .score-result {
      position: absolute;
      //box-shadow: 0 0 1px #000;
      top: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-weight: 700;
      font-size: 1.7em;

      .concept {
      }

      .points {
        font-size: 1.5em;
        line-height: 1em;
      }
    }

    .logo-comp {
      height: 100%;
      width: auto;
      max-width: none;
    }

  }

  .card-bottom {
    ion-item {
      cursor: pointer;
    }
    ion-card-content {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }

}
