[data-comp-pageheader]{
  h2 {
    font-size: 1.1em;
    margin: 0 0 5px;
  }
  .note {
    font-size: .8em;
    line-height: 1.2em;
    margin: 0;
    color: var(--brand-grey);
  }

  .box-note {
    background-color: #eee;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    &.finished {
      font-weight: 700;
    }
  }
}
